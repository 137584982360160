// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import "./../../../node_modules/@angular/material/theming";
@import "./component-theme";

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

//typography
$custom-typography: mat.define-typography-config(
  $font-family: 'Roboto',
  $display-4: mat.define-typography-level(16px, 28px, 400,), 
  $display-3: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em), 
  $display-2: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em), 
  $display-1: mat.define-typography-level(34px, 40px, 400),
  $headline:      mat.define-typography-level(24px, 32px, 400, "MontserratMedium"),
  $title:         mat.define-typography-level(20px, 32px, 500, "MontserratMedium"),
  $subheading-2:  mat.define-typography-level(16px, 28px, 400),
  $subheading-1:  mat.define-typography-level(15px, 24px, 400),
  // $body-2:        mat-typography-level(14px, 24px, 500),
  $body-2:        mat.define-typography-level(14px, 24px, 500, "MontserratMedium"),
  $body-1:        mat.define-typography-level(14px, 20px, 400),
  $caption:       mat.define-typography-level(12px, 20px, 400),
  $button:        mat.define-typography-level(14px, 14px, 500, "MontserratMedium"),
  // Line-height must be unit-less fraction of the font-size.
  $input:         mat.define-typography-level(inherit, 1.125, 400)
  );
@include mat.core($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
@import"./variables";

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$light-theme: mat.define-light-theme($light-primary, $light-accent, $light-warn);
$dark-theme: mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($light-theme);
@include custom-component-theme($light-theme);

/* Dark theme */

// Include the dark color styles inside of a block with a CSS class. You can make this
// CSS class whatever you want. In this example, any component inside of an element with
// `.dark-theme` will be affected by this alternate dark theme instead of the default theme.
.dark-theme {

  @include mat.all-component-themes($dark-theme);
  @include custom-component-theme($dark-theme);

}
// $primary: map-get($domo-primary, default);
// $accent: map-get($domo-accent, default);
// $warn: map-get($domo-warn, default);
