@font-face {
  font-family: "MontserratMedium";
  src: url("./assets/font/Montserrat-Medium.otf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/font/Roboto-Light.ttf") format("truetype");
}
@import "./app/style/material-styles";
@import "~ngx-sharebuttons/themes/circles/circles-dark-theme";

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  // background: linear-gradient(#212121, 20%, #591420, 70%, black);
  margin: 0;
  font-family: MontserratMedium, "Helvetica Neue", sans-serif;
}

button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

.light-typography {
  font-family: Roboto;
}

h3 {
  .light-typography {
    font-family: Roboto;
  }
}

.hidden {
  display: none;
}

/* alternate sizes for mat icon button */

button[mat-icon-button] {
  $large-size-button: 72px;
  $large-size-icon: 54px;

  &.icon-button-large {
    width: $large-size-button;
    height: $large-size-button;
    line-height: $large-size-button;
    .mat-icon {
      // font-size: $large-size-icon;
      width: $large-size-icon;
      height: $large-size-icon;
      line-height: $large-size-icon;
    }
    .mat-button-ripple {
      font-size: inherit;
      width: inherit;
      height: inherit;
      line-height: inherit;
    }
  }

  $medium-size-button: 56px;
  $medium-size-icon: 42px;

  &.icon-button-medium {
    width: $medium-size-button;
    height: $medium-size-button;
    line-height: $medium-size-button;
    .mat-icon {
      font-size: $medium-size-icon;
      width: $medium-size-icon;
      height: $medium-size-icon;
      line-height: $medium-size-icon;
    }
    .mat-button-ripple {
      font-size: inherit;
      width: inherit;
      height: inherit;
      line-height: inherit;
    }
  }

  $intermediate-size-button: 30px;
  $intermediate-size-icon: 20px;

  &.icon-button-intermediate {
    width: $intermediate-size-button;
    height: $intermediate-size-button;
    line-height: $intermediate-size-button;
    .mat-icon {
      font-size: $intermediate-size-icon;
      width: $intermediate-size-icon;
      height: $intermediate-size-icon;
      line-height: $intermediate-size-icon;
    }
    .mat-button-ripple {
      font-size: inherit;
      width: inherit;
      height: inherit;
      line-height: inherit;
    }
  }

  $small-size-button: 24px;
  $small-size-icon: 18px;

  &.icon-button-small {
    width: $small-size-button;
    height: $small-size-button;
    line-height: $small-size-button;
    .mat-icon {
      // font-size: $small-size-icon;
      width: $small-size-icon;
      height: $small-size-icon;
      line-height: $small-size-icon;
    }
    .mat-button-ripple {
      font-size: inherit;
      width: inherit;
      height: inherit;
      line-height: inherit;
    }
  }
}

/* Slider sliding state. override */
.mat-slider-sliding {
  .mat-slider-track-fill,
  .mat-slider-track-background,
  .mat-slider-thumb-container {
    // Must use `transition-duration: 0ms` to disable animation rather than `transition: none`.
    // On Mobile Safari `transition: none` causes the slider thumb to appear stuck.
    transition-duration: 10ms !important;
  }
}

.mat-slider-thumb-label {
  z-index: 10;
}

app-category-button {
  display: inline-block;
  width: 50%;
  min-width: 150px;
  max-width: 200px;
  padding: 0.3em;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  min-height: 150px;
  max-height: 200px;

  vertical-align: top;
}

app-nav-bar > div {
  // box-shadow: 0 -3px 5px -1px rgb(0 0 0 / 20%), 0 -6px 5px 0 rgb(0 0 0 / 14%), 0 -1px 5px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 -3px 5px -1px rgb(0 0 0 / 20%), 0 -1px 5px 0 rgb(0 0 0 / 14%), 0 -1px 1px 0 rgb(0 0 0 / 12%);
}

/* untils */

.vertical-centered {
  display: flex; /* contexte sur le parent */
  flex-direction: column; /* direction d'affichage verticale */
  justify-content: center; /* alignement vertical */
}

.custo-spacer {
  flex: 1 1 auto;
}

.maxWidth100 {
  max-width: 100%;
}

.iconNotMaterial {
  font-family: none;
}

svg {
  overflow: hidden;
  vertical-align: top;
}

/* Mat list item avatar */

.avatar {
  margin-right: 1em;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

#initLoader {
  height: 100%;
  display: flex;
  flex-direction: column;
  .init-spinner {
    margin: auto;
  }
}
