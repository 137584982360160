/** Custom theming **/
@use '@angular/material' as mat;
@mixin global-color-style($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  a,
  a:active,
  a:focus,
  a:hover {
    color: mat.get-color-from-palette($primary);
  }
}

@mixin app-nav-bar-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);

  .app-nav-bar {
    background-color: map-get($background, app-bar);
    .app-nav-bar-link {
      // color: map-get($foreground, text);
      color: map-get($foreground, text);
    }
    &.app-primary {
      background-color: mat.get-color-from-palette($primary);
      .app-nav-bar-link {
        color: map-get($primary, default-contrast);
      }
    }
    &.app-accent {
      background-color: mat.get-color-from-palette($accent);
      .app-nav-bar-link {
        color: map-get($accent, default-contrast);
      }
    }
    &.app-contrast {
      color: map-get($foreground, text);
    }
  }
}

@mixin app-mini-player-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);

  .app-mini-player {
    background-color: map-get($background, app-bar);
   
      color: map-get($foreground, text);
      border-top: solid;
      border-width: 1px;
      @if ($is-dark-theme == true) {
        border-color: #212121;
      } @else {
        border-color: #8080803b;
      }
    }
  
}

@mixin app-player-control-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);

  .app-player-control {
    @if ($is-dark-theme == true) {
      background-color: #212121;
    } @else {
      background-color: #f5f5f5;
    }
    // background-color: map-get($background, card);
    .icon-active {
      color: mat.get-color-from-palette($accent);
    }
  }
}

@mixin util-class($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $contrast: map-get(map-get($theme, foreground), text);
  // $contrast: map-get($theme, foreground, text);

  .app-accent {
    color: mat.get-color-from-palette($accent) !important;
  }

  .app-primary {
    color: mat.get-color-from-palette($primary) !important;
  }

  .app-warn {
    color: mat.get-color-from-palette($warn) !important;
  }

  .app-contrast {
    color: $contrast !important;
  }
}

@mixin mat-theme-override($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);

  /** side nav backdrop color. Too much light in dark theme from my point of view **/
  .mat-drawer-backdrop.mat-drawer-shown {
    @if ($is-dark-theme == true) {
      background-color: rgba(0, 0, 0, 0.32);
    }
  }
}

@mixin custom-component-theme($theme) {
  @include global-color-style($theme);
  @include app-nav-bar-theme($theme);
  @include app-mini-player-theme($theme);
  @include app-player-control-theme($theme);
  @include util-class($theme);
  @include mat-theme-override($theme);
}

$palette_example: (
  50: #feedef,
  100: #fed1d6,
  200: #eea1a1,
  300: #e57d7d,
  400: #f0615c,
  500: #f45344,
  600: #e64b44,
  700: #d4423d,
  800: #c73c36,
  900: #b7342c,
  A100: #8c9eff,
  A200: #536dfe,
  A400: #3d5afe,
  A700: #304ffe,
  contrast: (
    50: rgba(0, 0, 0, 0.87),
    100: rgba(0, 0, 0, 0.87),
    200: rgba(0, 0, 0, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(0, 0, 0, 0.87),
    A200: white,
    A400: white,
    A700: white,
  ),
  default: #d4423d,
  lighter: #fed1d6,
  darker: #b7342c,
  text: #d4423d,
  default-contrast: white,
  lighter-contrast: rgba(0, 0, 0, 0.87),
  darker-contrast: white,
  "50-contrast": rgba(0, 0, 0, 0.87),
  "100-contrast": rgba(0, 0, 0, 0.87),
  "200-contrast": rgba(0, 0, 0, 0.87),
  "300-contrast": white,
  "400-contrast": white,
  "500-contrast": white,
  "600-contrast": white,
  "700-contrast": white,
  "800-contrast": white,
  "900-contrast": white,
  "A100-contrast": rgba(0, 0, 0, 0.87),
  "A200-contrast": white,
  "A400-contrast": white,
  "A700-contrast": white,
  "contrast-contrast": null,
);
